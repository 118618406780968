import * as THREE from 'three';
import {DEG2RAD} from "three/src/math/MathUtils";
import {Matrix4} from "three/src/math/Matrix4";
import {Mesh, Vector3} from "three";
import {Observable} from "rxjs";
import {Euler} from "three/src/math/Euler";

class Globe {
  private canvas?: HTMLElement;
  private map?: HTMLElement;

  renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true
  });

  scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera(75, 800 / 600, 0.1, 1000);
  dotPositions: Vector3[] = [];

  globeMesh?: Mesh;
  mapData?: ImageData;
  tick = 0;

  shaders = {
    'earth' : {
      uniforms: {},
      vertexShader: [
        'varying vec3 vNormal;',
        'varying vec2 vUv;',
        'void main() {',
        'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
        'vNormal = normalize( normalMatrix * normal );',
        'vUv = uv;',
        '}'
      ].join('\n'),
      fragmentShader: [
        'precision mediump float;',
        'uniform sampler2D texture;',
        'varying vec3 vNormal;',
        'varying vec2 vUv;',
        'void main() {',
        'vec3 diffuse = vec3( 6.0 / 255.0, 166.0 / 255.0, 147.0 / 255.0 ) * vec3(0.05);',
        'float intensity = 1.05 - dot( vNormal, vec3( 0.0, 0.0, 1.0 ) );',
        'vec3 atmosphere = vec3( 16.0 / 255.0, 166.0 / 255.0, 147.0 / 255.0 ) * pow( intensity, 2.0 );',
        'gl_FragColor = vec4( diffuse + atmosphere, 1.0 );',
        '}'
      ].join('\n')
    },
    'atmosphere' : {
      uniforms: {},
      vertexShader: [
        'varying vec3 vNormal;',
        'void main() {',
        'vNormal = normalize( normalMatrix * normal );',
        'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
        '}'
      ].join('\n'),
      fragmentShader: [
        'varying vec3 vNormal;',
        'void main() {',
        'float intensity = pow( 0.8 - dot( vNormal, vec3( 0, 0, 1.0 ) ), 2.0 );',
        'gl_FragColor = vec4( 6.0 / 255.0, 166.0 / 255.0, 147.0 / 255.0, 1.0 ) * intensity;',
        '}'
      ].join('\n')
    }
  };

  constructor(globeCanvasElementId: string, mapCanvasElementId: string) {
    this.canvas = document.getElementById(globeCanvasElementId);
    this.map = document.getElementById(mapCanvasElementId);

    this.camera = new THREE.PerspectiveCamera(75, this.canvas.offsetWidth / this.canvas.offsetHeight, 0.1, 1000);
    this.renderer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight);

    this.canvas.onresize = (event) => {
      this.renderer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight);
      this.camera = new THREE.PerspectiveCamera(75, this.canvas.offsetWidth / this.canvas.offsetHeight, 0.1, 1000);
    }

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.canvas?.appendChild(this.renderer.domElement);

    this.getMapData().subscribe(mapData => {
      this.init();

      this.renderer.setAnimationLoop(() => {
        this.update();
        this.render();
      });
    });
  }

  init() {
    const GLOBE_RADIUS = 10;
    const dotDensity = 5;
    const rows = 200;

    this.camera.position.z = 17;

    for (let lat = -180; lat <= 180; lat += 180 / rows) {
      const radius = Math.cos(Math.abs(lat) * DEG2RAD) * GLOBE_RADIUS;
      const circumference = radius * Math.PI * 2;
      const dotsForLat = circumference * dotDensity;

      for (let x = 0; x < dotsForLat; x++) {
        const long = -180 + x * 360 / dotsForLat;
        if (!this.visibilityForCoordinate(long, lat)) continue;

        const position = this.worldTransform(lat, long, GLOBE_RADIUS);

        this.dotPositions.push(position);
      }
    }

    const geometry = new THREE.CircleGeometry(0.05, 16);
    const material = new THREE.MeshBasicMaterial();
    const instancedLandDot = new THREE.InstancedMesh(geometry, material, this.dotPositions.length);

    this.dotPositions.forEach((position, index) => {
      let matrix = new Matrix4();
      matrix.setPosition(position);
      matrix = matrix.lookAt(new Vector3(0,0,0), position.multiply(new Vector3(-1.0, -1.0, -1.0)), new Vector3(0.0, -1.0, 0.0));

      instancedLandDot.setMatrixAt(index, matrix);
    });

    instancedLandDot.instanceMatrix.needsUpdate = true;

    const globeGeometry = new THREE.SphereGeometry(GLOBE_RADIUS, 128, 128);
    const shader = this.shaders['earth'];
    const uniforms = THREE.UniformsUtils.clone(shader.uniforms);
    const globeMaterial = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
    });

    this.globeMesh = new THREE.Mesh(globeGeometry, globeMaterial);
    this.globeMesh.add(instancedLandDot);
    this.scene.add(this.globeMesh);

    const atmosphere = this.shaders['atmosphere'];
    const atmosphereUniforms = THREE.UniformsUtils.clone(shader.uniforms);
    const atmosphereMaterial = new THREE.ShaderMaterial({
      uniforms: atmosphereUniforms,
      vertexShader: atmosphere.vertexShader,
      fragmentShader: atmosphere.fragmentShader,
      side: THREE.BackSide,
      blending: THREE.AdditiveBlending,
      transparent: true
    });

    const atmosphereMesh = new THREE.Mesh(globeGeometry, atmosphereMaterial);
    atmosphereMesh.scale.set( 1.1, 1.1, 1.1 );
    this.scene.add(atmosphereMesh);
  }

  worldTransform(lat: number, long: number, radius: number) {
    let position = new Vector3();

    const i = (90 - lat) * (Math.PI / 180);
    const r = (long + 180) * (Math.PI / 180);
    position.set(-radius * Math.sin(i) * Math.cos(r), radius * Math.cos(i), radius * Math.sin(i) * Math.sin(r));

    return position;
  }

  getMapData(): Observable<ImageData> {
    return new Observable(observable => {
      new THREE.ImageLoader().load( "assets/images/map-gh.png", image => {
        const mapContext = (this.map as HTMLCanvasElement)?.getContext('2d');
        mapContext!.globalCompositeOperation = "copy";
        mapContext!.canvas.width = image.width;
        mapContext!.canvas.height = image.height;

        mapContext?.drawImage(image, 0, 0);
        this.mapData = mapContext?.getImageData(0,0, image.width, image.height);

        console.log(this.mapData);
        observable.next(this.mapData);
        observable.complete();
      }, undefined, (event: ErrorEvent) => {
        console.log(event);
      });
    });
  }

  visibilityForCoordinate(long: number, lat: number): boolean {
    if (this.mapData) {
      const indexOffset = 4 * this.mapData.width;
      const r = parseInt(((long + 180) / 360 * this.mapData.width + .5).toFixed(0));
      const o = this.mapData.height - parseInt(((lat + 90) / 180 * this.mapData.height - .5).toFixed(0));
      const a = parseInt(((indexOffset * (o - 1) + 4 * r) + 3).toFixed(0));

      return this.mapData.data[a] > 90
    }

    return false;
  }

  degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  update(): void {
    this.tick++;
    const xRotation = 2.5 * Math.sin(this.tick / 500) + 15;
    const yRotation = -5 * Math.cos(this.tick / 500) + 130;

    this.globeMesh?.setRotationFromEuler(new Euler(xRotation * -1.0 * DEG2RAD,yRotation * DEG2RAD,0));
  }

  render(): void {
    this.renderer.render(this.scene, this.camera);
  }
}

new Globe("canvas", "map");
